@import "@/styles/variables";

.phoneEnterForm {
  &_formLabel {
    display: block;
    margin-bottom: $spacing-3;
  }

  &_inputGroup {
    display: flex;
    gap: $spacing-3;
    align-items: flex-start;
    position: relative;
  }

  &_codeSelect {
    border-bottom-right-radius: unset;
    border-bottom-left-radius: unset;
  }

  &_input {
    width: 100%;
    position: relative;
  }

  &_phoneInput {
    width: 100%;
    display: flex;
    gap: $spacing-2;
    border-bottom-right-radius: $border-radius-base;
    border-bottom-left-radius: $border-radius-base;
    border: 1px solid $color-gray;
    border-top-color: #fff;
    padding: 18px;
    font-size: $font-size-base;

    &:hover {
      border-color: $color-gray-dark;
    }

    &__isFocused {
      outline: unset;
      border-color: $color-green-main;
      box-shadow: 0 0 6px 2px $color-green-main;
    }

    &__isInvalid {
      border-color: $color-red;
      box-shadow: 0 0 6px 2px $color-red;
    }

    &__isDisabled {
      border-color: $color-gray;
      background-color: $color-gray-light;
      color: hsl(0, 0%, 60%);
      pointer-events: none;
    }
  }

  &_code {
    text-wrap: nowrap;
    padding-right: 0;
    border-right: unset;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  &_inputField {
    width: 100%;
    line-height: 0;
    padding-left: 0;
    border: unset;
    font-size: $font-size-base;
    outline: unset;
    font-family: inherit;

    &:disabled,
    &[disabled] {
      pointer-events: none;
      border-color: $color-gray;
      background-color: $color-gray-light;
      color: hsl(0, 0%, 60%);
    }
  }

  &_errorText {
    position: absolute;
    bottom: -26px;
    left: 0;
    color: $color-red;
    font-size: $font-size-s;
  }

  &_continue {
    margin: 40px auto 0;
    max-width: 320px;
  }
}
