@import "@/styles/variables.scss";

.genderForm {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: $spacing-4;

  &_genderField {
    width: 260px;
  }
}

