@import "@/styles/variables";

.selectNative {
  position: relative;
  width: 100%;

  &:hover {
    .selectNative_arrow {
      color: $color-black;
    }
  }

  &_select {
    width: 100%;
    padding: $spacing-5 34px $spacing-5 $spacing-5;
    font-size: $font-size-base;
    border-radius: $border-radius-base;
    border: 1px solid $color-gray;
    background-color: $color-white;
    color: $color-black;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:hover {
      border-color: $color-black;
    }

    &:focus {
      border-color: $color-green-main;
      box-shadow: 0 0 6px 2px $color-green-main;
      outline: none;
    }

    &::-ms-expand {
      display: none;
    }
  }

  &_arrow {
    position: absolute;
    right: $spacing-5;
    transform: translateY(-50%);
    top: 50%;
    z-index: 1;
    color: $color-grey2;
  }
}
